.toast-notification-component {
  position: fixed;
  width: 100%;
  top: 6rem;
  left: 0;
  z-index: 999;
  pointer-events: none;

  .container {
    max-width: 50rem;
  }

  .panel {
    background: white;
    box-shadow: 0 0 1.5rem rgba(37, 37, 37, 0.1);
    transform: translateY(-500px);
    opacity: 0;
    transition: all 1s;
    transition-property: transform, opacity;

    .icon {
      width: 2.5rem;
      max-width: 100%;
    }

    h3 {
      font-weight: 700;
      font-size: 1.125rem;
      color: #252525;
    }

    p {
      font-weight: 500;
      font-size: 1rem;
      color: #696e74;
    }

    .close.button {
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      width: 0.8rem;
      height: 0.8rem;
      cursor: pointer;
      pointer-events: all;

      img {
        width: inherit;
        height: inherit;
      }
    }
  }

  .panel.is-visible {
    transform: translateY(-1rem);
    opacity: 1;
  }
}
